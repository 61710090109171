import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Scroll from 'react-scroll';
import { PATHS } from '../../../utils/paths';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#00AA4F',
    color: 'white',
    border: '1px solid transparent',
    borderRadius: '5px',
    zIndex: 9,
    fontWeight: 500,
    marginLeft: 25,
    '&:focus': {
      backgroundColor: '#00AA4F'
    },
    '&:hover': {
      backgroundColor: '#00AA4F'
    },
    '&:active': {
      backgroundColor: '#00AA4F'
    },
    [theme.breakpoints.down('md')]: {
      padding: '3px',
      fontSize: '10px',
      marginLeft: '15px'
    }
  }
}));

const handleSelectSchedule = () => {
  Scroll.scroller.scrollTo('quoteRequestSection', {
    duration: 1000,
    delay: 100,
    smooth: 'easeInOutQuint'
  });
};

export default function ClassesNesting() {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.root
      }}
      href={PATHS.quoteRequest}
    >
      Get Quote
    </Button>
  );
}
