import { createStore, applyMiddleware, compose, Middleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import logger from 'redux-logger';
import rootReducer from '../reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const initialState = {};
export const history = createBrowserHistory();

let middleware: Middleware[] = [thunk];

// only add logger in development to avoid noise in production and CI
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
}

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose
  )
);

export default store;
