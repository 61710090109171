import { combineReducers } from 'redux';
import auth from './auth';
import ports from './ports';
import quoteRequest from './quoteRequest';
import scroll from './scroll';

const rootReducer = combineReducers({
  auth: auth,
  ports: ports,
  quoteRequest: quoteRequest,
  scroll: scroll
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
