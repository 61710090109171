import { apiAction, makeActions } from '../utils/actionUtils';

const dict = {
  Auth: ['SET_AUTH', 'SET_USER_CREDENTIALS', 'RESET_USER_CREDENTIALS', ...apiAction('GET_AUTH_CONFIG')],
  Scroll: ['SCROLL_TO_SECTION'],
  Ports: [
    'STORE_DEPARTURE_PORT_ID',
    'STORE_DESTINATION_PORT_ID',
    'STORE_DESTINATION_PORTS',
    'STORE_LOADING_DATE',
    'STORE_SHARED_ROUTE',
    'RESET_SCHEDULES',
    ...apiAction('GET_PORTS'),
    ...apiAction('GET_SCHEDULES'),
    ...apiAction('GET_NEAREST_SHIPPINGS')
  ],
  QuoteRequest: [
    'SET_USER_SELECT_SCHEDULE',
    'SET_FIRST_NAME',
    'SET_FIRST_NAME_ERROR',
    'SET_LAST_NAME',
    'SET_LAST_NAME_ERROR',
    'SET_PHONE_NUMBER',
    'SET_PHONE_NUMBER_ERROR',
    'SET_EMAIL',
    'SET_EMAIL_ERROR',
    'SET_BEST_TIME_TO_CONTACT',
    'SET_YACHT_MODEL',
    'SET_YEAR',
    'SET_LENGTH',
    'SET_LENGTH_UNIT',
    'SET_BEAM',
    'SET_BEAM_UNIT',
    'SET_WEIGHT',
    'SET_WEIGHT_UNIT',
    'SET_PURPOSE',
    'SET_FROM_WHERE',
    'SET_TO_WHERE',
    'SET_WHEN',
    'SET_NOTES',
    'SET_YACHT_NAME',
    'RESET_QUOTE_REQUEST_FORM',
    'RESET_QUOTE_REQUEST_ERRORS',
    'STORE_USER_SHIPPING_SELECTION',
    'CLEAR_USER_SHIPPING_SELECTION',
    ...apiAction('POST_QUOTE_REQUEST')
  ]
};

export default makeActions(dict) as any;
