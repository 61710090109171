import React, { FC } from 'react';

const LoadingIndicator: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: '20px',
        marginTop: '50px',
        color: '#3365A7'
      }}
    >
      Loading...
    </div>
  );
};

export default LoadingIndicator;
