import actions from '../actions/types';

const emptyUser = {
  name: '',
  email: ''
};

export const initialState = {
  accessToken: null,
  idToken: null,
  expiresAt: null,
  scope: '',
  isAuthenticated: false,
  user: emptyUser,
  loading: false,
  authConfig: {},
  auth: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.Auth.SET_AUTH:
      return {
        ...state,
        auth: action.payload
      };
    case actions.Auth.GET_AUTH_CONFIG_SUCCESS:
      return {
        ...state,
        authConfig: action.payload
      };
    case actions.Auth.SET_USER_CREDENTIALS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        idToken: action.payload.idToken,
        expiresAt: action.payload.expiresAt,
        scope: action.payload.scope,
        isAuthenticated: true,
        user: {
          ...action.payload.user,
          name: action.payload.user.name,
          email: action.payload.user.email
        }
      };

    case actions.Auth.RESET_USER_CREDENTIALS:
      return {
        ...state,
        accessToken: null,
        idToken: null,
        expiresAt: null,
        isAuthenticated: false,
        user: {
          name: '',
          email: ''
        }
      };

    default:
      return state;
  }
}
