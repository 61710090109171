import actions from '../actions/types';

export const defaultUserShippingSelectionState = {
  fromWhere: '',
  toWhere: '',
  when: ''
};
export const defaultState = {
  ...defaultUserShippingSelectionState,
  isLoading: false
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.QuoteRequest.STORE_USER_SHIPPING_SELECTION: {
      return {
        ...state,
        fromWhere: action.payload.fromWhere,
        toWhere: action.payload.toWhere,
        when: action.payload.when
      };
    }
    case actions.QuoteRequest.CLEAR_USER_SHIPPING_SELECTION: {
      return {
        ...state,
        ...defaultUserShippingSelectionState
      };
    }

    case actions.QuoteRequest.POST_QUOTE_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case actions.QuoteRequest.POST_QUOTE_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }

    case actions.QuoteRequest.POST_QUOTE_REQUEST_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }

    default:
      return state;
  }
};

export default reducer;
