export const maxComputerDate = new Date(2037, 11, 31, 23, 59, 59);

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

/**
 * returns date formatted like `Short month name day, year`
 *
 * @export
 * @param {Date} date
 * @returns {String}
 */
export function getInternationalDateFormat(date) {
  const newDate = new Date(date);
  const year = newDate.getUTCFullYear();
  const month = months[newDate.getUTCMonth()];
  const day = newDate.getUTCDate();
  return `${month} ${day}, ${year}`;
}

export const isDate = function (date) {
  const parsedDate = Date.parse(date);

  // You want to check again for !isNaN(parsedDate) here because Dates can be converted
  // to numbers, but a failed Date parse will not.
  return isNaN(date) && !isNaN(parsedDate);
};

export const nowUTC = () => {
  const date = new Date();
  const nowUtc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(nowUtc);
};

export const datesDifferenceInDays = (date1, date2) => {
  const firstDay = new Date(date1).getTime();
  const lastDay = new Date(date2).getTime();
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffDays = Math.round(Math.abs((firstDay - lastDay) / oneDay));

  return diffDays;
};
