import actions from '../actions/types';
import { isObject } from '../utils/helpers';

export const emptyPortsErrors = {
  departurePortId: '',
  destinationPortId: ''
};

export const defaultPortsState = {
  isLoadingPorts: false,
  isLoadingSchedule: false,
  destinationIndex: null,
  destinationPorts: [],
  portsList: [],
  destinations: [],
  departurePortId: '',
  destinationPortId: '',
  loadingDate: null,
  errors: emptyPortsErrors,
  schedules: [],
  errorMessage: '',
  sharedRoute: null
};

export const defaultState = defaultPortsState;

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.Ports.STORE_SHARED_ROUTE: {
      return {
        ...state,
        sharedRoute: action.payload
      };
    }
    case actions.Ports.GET_PORTS: {
      return {
        ...state,
        isLoadingPorts: true
      };
    }

    case actions.Ports.GET_PORTS_SUCCESS: {
      return {
        ...state,
        destinationPorts: action.payload.ports,
        portsList: action.payload.ports,
        destinations: action.payload.destinations,
        isLoadingPorts: false
      };
    }

    case actions.Ports.GET_PORTS_FAILURE: {
      return {
        ...state,
        isLoadingPorts: false,
        errorMessage: action.payload
      };
    }

    case actions.Ports.GET_SCHEDULES: {
      return {
        ...state,
        schedules: [],
        isLoadingSchedule: true
      };
    }

    case actions.Ports.GET_SCHEDULES_SUCCESS: {
      return {
        ...state,
        schedules: action.payload,
        errors: emptyPortsErrors,
        isLoadingSchedule: false,
        errorMessage: ''
      };
    }

    case actions.Ports.GET_SCHEDULES_FAILURE: {
      if (isObject(action.payload)) {
        return {
          ...state,
          errors: action.payload,
          isLoadingSchedule: false,
          errorMessage: ''
        };
      } else {
        return {
          ...state,
          errors: emptyPortsErrors,
          isLoadingSchedule: false,
          errorMessage: action.payload
        };
      }
    }

    case actions.Ports.GET_NEAREST_SHIPPINGS: {
      return {
        ...state,
        schedules: [],
        isLoadingSchedule: true
      };
    }

    case actions.Ports.GET_NEAREST_SHIPPINGS_SUCCESS: {
      return {
        ...state,
        schedules: action.payload,
        errors: emptyPortsErrors,
        isLoadingSchedule: false,
        errorMessage: ''
      };
    }

    case actions.Ports.GET_NEAREST_SHIPPINGS_FAILURE: {
      if (isObject(action.payload)) {
        return {
          ...state,
          errors: action.payload,
          isLoadingSchedule: false,
          errorMessage: ''
        };
      } else {
        return {
          ...state,
          errors: emptyPortsErrors,
          isLoadingSchedule: false,
          errorMessage: action.payload
        };
      }
    }

    case actions.Ports.RESET_SCHEDULES: {
      const newState = {
        ...state,
        schedules: []
      };
      return newState;
    }
    case actions.Ports.STORE_DEPARTURE_PORT_ID: {
      return {
        ...state,
        schedules: [],
        departurePortId: action.payload
      };
    }

    case actions.Ports.STORE_DESTINATION_PORTS: {
      return {
        ...state,
        schedules: [],
        destinationIndex: action.payload.destinationIndex,
        destinationPorts: action.payload.ports,
        destinationPortId: ''
      };
    }

    case actions.Ports.STORE_LOADING_DATE: {
      return {
        ...state,
        schedules: [],
        loadingDate: action.payload
      };
    }

    case actions.Ports.STORE_DESTINATION_PORT_ID: {
      return {
        ...state,
        schedules: [],
        destinationPortId: action.payload
      };
    }

    default:
      return state;
  }
};

export default reducer;
