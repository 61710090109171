export enum Messages {
  NotAuthorized = 'Not authorized',
  SailingNameRequired = 'Sailing Name is required',
  DestinationPortRequired = 'Destination Port is required',
  ShipRequired = 'Ship is required',
  ArrivalDateRequired = 'Arrival Date is required',
  DepartureDateRequired = 'Departure Date is required',
  MileRequired = 'Mile is required',
  DaysAtSeaRequired = 'Days at Sea is required',
  ShipStopsRequired = 'At least one ShipStop is required',
  SailingDeletesSuccessfully = 'Sailing deletes successfully',
  FailedDeleteSailing = 'Failed to delete sailing',
  FailedGetSailing = 'Failed to get sailing info',
  FailedChangeSailingStatus = 'Failed to change Sailing status'
}
