import React, { CSSProperties, FC } from 'react';
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const colors = {
  success: '#00AA4F'
};

const textContrastColors = {
  success: '#fff'
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: (props: CSSProperties) => {
      return colors[props.color] || theme.palette.primary.main;
    },
    color: (props: CSSProperties) => {
      return textContrastColors[props.color] || '#fff';
    },
    '&:hover': {
      background: (props: CSSProperties) => {
        return fade(colors[props.color], 0.9) || theme.palette.primary.main;
      }
    }
  }
}));

interface StyledButtonProps {
  color: 'success' | 'primary' | 'warning';
}

const StyledButton: FC<Omit<ButtonProps, 'color'> & StyledButtonProps> = ({ children, color, ...props }) => {
  const classes = useStyles({ ...props, color });
  return (
    <Button className={classes.root} {...props}>
      {children}
    </Button>
  );
};

export default StyledButton;
