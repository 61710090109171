import { MenuItem, MenuList, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC, useMemo } from 'react';
import Scroll from 'react-scroll';
import { Link } from 'react-router-dom';
import Auth from '../../auth/Auth';
import { useHistory } from 'react-router-dom';
import { Scopes } from '@efacity/common';
import {PATHS} from "../../utils/paths";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    color: 'white',
    backgroundColor: '#0A2A3B',
    borderRadius: 0,
    height: '100%',
    width: 220,
    padding: '60px 12px 20px',
    display: 'flex',
    flexDirection: 'column'
  },
  closeButton: {
    position: 'absolute',
    padding: 0,
    color: '#fff',
    cursor: 'pointer',
    right: '180px',
    top: '20px'
  },
  menuLink: {
    color: 'white',
    fontWeight: 600,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '17px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    display: 'inline-block',
    cursor: 'pointer',
    textAlign: 'left',
    padding: '5px 0 5px',
    width: '100%'
  },
  menuItemDivider: {
    border: 'none',
    color: '#00b39e',
    backgroundColor: '#00b39e',
    height: '1px'
  },
  linksContainer: {
    position: 'relative',
    top: 0,
    flexGrow: 1
  },
  authButtons: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  }
}));

interface TopMenuLinksListProps {
  handleClose: (event?: any) => void;
  open?: boolean;
  handleListKeyDown?: (event: any) => void;
  isAuthenticated?: boolean;
  handleSignIn?: (event: any) => void;
  handleSignOut?: (event: any) => void;
  menuLinks: any[];
}

const TopMenuLinksList: FC<TopMenuLinksListProps> = ({
  handleClose,
  open,
  handleListKeyDown,
  isAuthenticated,
  handleSignIn,
  handleSignOut,
  menuLinks
}) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = new Auth(history);

  const menuLinksCalculated = useMemo(() => {
    if (isAuthenticated && auth.checkIfUserHasScope([Scopes.UpdateSchedule])) {
      return [
        ...menuLinks,
        {
          label: 'Edit schedule',
          link: PATHS.scheduleManagement,
          sectionName: 'quoteRequestSection',
          scrollDuration: 500
        }
      ];
    }
    return menuLinks;
  }, [isAuthenticated]);

  const onMenuItemSelect = menuItem => {
    handleClose();
    Scroll.scroller.scrollTo(menuItem.sectionName, {
      duration: menuItem.scrollDuration,
      delay: 100,
      smooth: 'easeInOutQuint'
    });
  };

  return (
    <Paper className={classes.wrapper}>
      <CloseIcon data-cy="close-menu-icon" onClick={handleClose} className={classes.closeButton} />
      <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown} className={classes.linksContainer}>
        {menuLinksCalculated.map((menuItem, index) => (
          <div key={index}>
            {menuItem.link ? (
              <MenuItem data-cy="menu-item" component={Link} to={menuItem.link} style={{ paddingLeft: 0 }}>
                <Typography align="left" className={classes.menuLink}>
                  {menuItem.label}
                </Typography>
              </MenuItem>
            ) : (
              <MenuItem data-cy="menu-item" onClick={() => onMenuItemSelect(menuItem)} style={{ paddingLeft: 0 }}>
                <Typography align="left" className={classes.menuLink}>
                  {menuItem.label}
                </Typography>
              </MenuItem>
            )}
            <hr className={classes.menuItemDivider} />
          </div>
        ))}
      </MenuList>

      <MenuList className={classes.authButtons}>
        {isAuthenticated ? (
          <MenuItem data-cy="sign-out-btn" style={{ paddingLeft: 0 }} onClick={handleSignOut}>
            <Typography align="left" className={classes.menuLink}>
              Sign out
            </Typography>
          </MenuItem>
        ) : (
          <MenuItem data-cy="sign-in-btn" style={{ paddingLeft: 0 }} onClick={handleSignIn}>
            <Typography align="left" className={classes.menuLink}>
              Sign in
            </Typography>
          </MenuItem>
        )}
      </MenuList>
    </Paper>
  );
};

export default TopMenuLinksList;
