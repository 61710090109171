import React, { Component, Suspense } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import Landing from './components/layout/Landing';
import Auth from './auth/Auth';
import { PATHS } from './utils/paths';
import { setAuth } from './actions/auth';
import Callback from './components/auth/Callback';
import { getAuthConfig } from './actions/auth/api';
import LoadingIndicator from './components/shared/LoadingIndicator/LoadingIndicator';

const Destination = React.lazy(() => import('./pages/Destination/Destination'));
const GalleryPage = React.lazy(() => import('./components/GalleryPage/gal'));
const Services = React.lazy(() => import('./components/ServicesPage/Services'));
const QuotePage = React.lazy(() => import('./components/QuotePage/QuotePage'));
const ScheduleManagement = React.lazy(() => import('./components/layout/ScheduleManagment/ScheduleManagement'));
const YachtGuide = React.lazy(() => import('./components/YachtGuide/YachtGuide'));
const PrivacyPolicy = React.lazy(() => import('./components/layout/PrivacyPolicyPage/PrivacyPolicy'));

interface AuthAppState {
  tokenRenewalComplete: boolean;
}

class AuthApp extends Component<PropsFromRedux & RouteComponentProps, AuthAppState> {
  constructor(props) {
    super(props);
    this.state = {
      tokenRenewalComplete: false
    };
  }

  componentDidMount() {
    this.props.getAuthConfig().then(() => {
      this.props.setAuth(new Auth(this.props.history));
      this.props.auth.renewToken(() => {
        this.setState({ tokenRenewalComplete: true });
      });
    });
  }

  render() {
    if (!this.state.tokenRenewalComplete) return <LoadingIndicator />;

    return (
      <div className="App" style={{ margin: 0 }}>
        <Suspense fallback={<LoadingIndicator />}>
          <Route exact path={PATHS.destination} component={Destination} />
          <Route exact path={PATHS.landing} component={Landing} />
          <Route exact path={PATHS.gallery} component={GalleryPage} />
          <Route exact path={PATHS.services} component={Services} />
          <Route exact path={PATHS.quoteRequest} component={QuotePage} />
          <Route exact path={PATHS.instructions} component={YachtGuide} />
          <Route exact path={PATHS.privacyPolicy} component={PrivacyPolicy} />
          <Route exact path={PATHS.authCallback} component={Callback} />
          <Route exact path={PATHS.scheduleManagement} component={ScheduleManagement} />
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  ports: state.ports
});

const mapActionsToProps = dispatch => ({
  dispatch,
  setAuth: auth => {
    dispatch(setAuth(auth));
  },
  getAuthConfig: () => {
    return dispatch(getAuthConfig());
  }
});

const connector = connect(mapStateToProps, mapActionsToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AuthApp);
