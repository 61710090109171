// Core
import { Box, SwipeableDrawer, Theme } from '@material-ui/core';
// Theme
import { makeStyles } from '@material-ui/core/styles';
import Header from '../../../components/shared/Header';
// Assets
import React, { useState } from 'react';
// External
import TopMenuLinksList from '../TopMenuLinksList';
// Internal
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Auth from '../../../auth/Auth';
import { RootState } from '../../../reducers';
import { menuLinks } from '../menuLinks';
import HeroSection from '../HeroSection/HeroSection';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: '85%'
    }
  },
  contentContainer: {
    padding: '1%',
    backgroundColor: 'rgba(10, 42, 59, 0.9)',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '2%'
    }
  },
  icon: {
    margin: 20,
    opacity: 1,
    zIndex: 100
  },
  title: {
    width: '100%',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontSize: '40px',
    fontWeight: 'bold',
    color: 'white',
    lineHeight: '100%',
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '26px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '26px',
      marginBottom: '14px'
    }
  },
  getQuoteBtn: {
    backgroundColor: '#00AA4F',
    border: '1px solid transparent',
    borderRadius: '5px',
    position: 'inherit',
    zIndex: 9,
    fontWeight: 600,
    margin: 20,
    '&:hover': {
      border: '1px solid #FFFFFF',
      backgroundColor: 'transparent'
    },
    '&:focus': {
      border: '1px solid #FFFFFF',
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6px 15px'
    }
  },
  textWrapper: {
    maxWidth: '80%',
    margin: '0 auto'
  }
}));

export default function LandingWithSliderView() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  const classes = useStyles();
  const auth = new Auth(history);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setDrawerOpen(false);
    }
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSignIn = () => {
    auth.login();
  };

  const handleSignOut = () => {
    auth.logout();
  };

  return (
    <div className={classes.root}>
      <Header onOpenMenu={handleDrawerOpen} isMenuOpened={drawerOpen} />
      <SwipeableDrawer
        data-cy="menu-drawer"
        open={drawerOpen}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        style={{ opacity: 0.9 }}
      >
        <TopMenuLinksList
          isAuthenticated={isAuthenticated}
          handleSignIn={handleSignIn}
          handleSignOut={handleSignOut}
          handleListKeyDown={handleListKeyDown}
          handleClose={handleDrawerClose}
          menuLinks={menuLinks}
        />
      </SwipeableDrawer>
    </div>
  );
}
