export enum ValidationMessages {
  SailingNameRequired = 'Sailing name is required',
  ShipRequired = 'Ship is required',
  ShipStopsRequired = 'Sailing should have at least one ship stop',
  PortIdRequired = 'Port is required',
  DepartureOnShouldBeAfterArrivalOn = 'Departure date should be after arrival date',
  ArrivalOnShouldBeBeforeDepartureOn = 'Arrival date should be before departure date',
  NonNegativeMiles = 'Miles should not be negative',
  MilesRequired = 'Miles are required'
}
