// Core
import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

// Theme
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// External
import { SnackbarProvider } from 'notistack';

// Internal
import AuthApp from './AuthApp';
import { customTheme } from './customTheme';

import store from './store/store';
import './stylesheets/main.css';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={customTheme}>
          <Router>
            <SnackbarProvider maxSnack={3} hideIconVariant>
              <CssBaseline />
              <Route component={AuthApp} />
            </SnackbarProvider>
          </Router>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
