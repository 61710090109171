import React, { FC, Suspense } from 'react';
import { daisy_small } from '../../../images';

const HeroMobile: FC = () => {
  return (
    <div style={{ marginTop: 84, height: '80vh' }}>
      <div style={{ marginTop: '10%', height: '75vh' }}>
        <video autoPlay loop muted playsInline poster={daisy_small}>
          <source src="https://alliedyacht.s3.amazonaws.com/IMG_4714_Trim_2M.mp4" type="video/mp4"></source>
        </video>
      </div>
    </div>
  );
};

export default HeroMobile;
