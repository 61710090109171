import ArrowUpward from '@material-ui/icons/ArrowUpward';
import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Scroll from 'react-scroll';
import ScrollToTop from 'react-scroll-up';
//import AboutUsSection from './AboutUsSection/AboutUsSection';
//import ContactUsSection from './ContactUsSection/ContactUsSection';
import LandingWithSliderView from './LandingWithSliderView/LandingWithSliderView';
//import SchedulesSection from './SchedulesSection/SchedulesSection';
//import TestimonialsSection from './TestimonialsSection/TestimonialsSection';
//import MyGallery from './GallerySection/Gallery';
//import { VideoGallery } from './VideoGallerySection/VideoGallery';
import { pageview } from '../../seo/gtag';
import { PATHS } from '../../utils/paths';
import HeroSection from './HeroSection/HeroSection';
import { Box } from '@mui/material';

const SchedulesSection = React.lazy(() => import('./SchedulesSection/SchedulesSection'));
const MyGallery = React.lazy(() => import('./GallerySection/Gallery'));
const VideoGallery = React.lazy(() => import('./VideoGallerySection/VideoGallery'));
const TestimonialsSection = React.lazy(() => import('./TestimonialsSection/TestimonialsSection'));
const AboutUsSection = React.lazy(() => import('./AboutUsSection/AboutUsSection'));
const ContactUsSection = React.lazy(() => import('./ContactUsSection/ContactUsSection'));

export class Landing extends Component<PropsFromRedux> {
  constructor(props) {
    super(props);
    this.handleSelectSchedule = this.handleSelectSchedule.bind(this);
  }

  componentDidMount() {
    pageview(PATHS.landing);

    setTimeout(() => {
      if (this.props.scroll.sectionToScroll) {
        Scroll.scroller.scrollTo(this.props.scroll.sectionToScroll, {
          duration: 1000,
          smooth: 'easeInOutQuint'
        });
      }
    }, 300);
  }

  handleSelectSchedule() {
    Scroll.scroller.scrollTo('quoteRequestSection', {
      duration: 1000,
      delay: 100,
      smooth: 'easeInOutQuint'
    });
  }

  render() {
    return (
      <div style={{ margin: 0, padding: 0 }}>
        <LandingWithSliderView />
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <HeroSection />
          <SchedulesSection onSelectSchedule={this.handleSelectSchedule} />
          <MyGallery />
          <VideoGallery />
          <TestimonialsSection />
          <AboutUsSection />
          <ContactUsSection />
        </Box>
        <ScrollToTop showUnder={200} style={{ color: '#006EB9' }}>
          <span>
            <ArrowUpward color="primary" style={{ transform: 'scale(1.8)' }} />
          </span>
        </ScrollToTop>
      </div>
    );
  }
}

const mapStateToProps = state => ({ scroll: state.scroll });
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Landing);
