import actions from '../actions/types';

export const defaultScrollState = {
  sectionToScroll: null
};

const reducer = (state = defaultScrollState, action) => {
  switch (action.type) {
    case actions.Scroll.SCROLL_TO_SECTION: {
      return {
        ...state,
        sectionToScroll: action.payload
      };
    }

    default:
      return state;
  }
};

export default reducer;
