import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (
  // eslint-disable-next-line no-restricted-globals
  location.protocol !== 'https:' &&
  // eslint-disable-next-line no-restricted-globals
  location.hostname.indexOf('allied-yacht.com') > -1
) {
  // eslint-disable-next-line no-restricted-globals
  location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
