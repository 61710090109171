export { default as addressIcon } from '../assets/icons/address.svg';
export { default as contactIcon } from '../assets/icons/phone.svg';
export { default as horizontalLogo } from './logo.svg';
export { default as aytLogo120 } from './allied_yacht_vertical_png_120.png';
export { default as logoSm } from './logo_sm.svg';
export { default as directCall } from './direct_call.svg';

export { default as alliedTransportImageCarouselFirstSlide } from './landing_slider_first_slide_1440x900.jpg';
export { default as alliedTransportImageCarouselSecondSlide } from './landing_slider_second_slide_1440x900.jpg';
export { default as alliedTransportImageCarouselThirdSlide } from './landing_slider_third_slide_1440x900.jpg';
export { default as alliedTransportImageCarouselFourthSlide } from './landing_slider_fourth_slide_1440х798.jpg';

export { default as alliedTransportImageCarouselFirstSlideMobile } from './landing_slider_first_slide_600х800.jpg';
export { default as alliedTransportImageCarouselSecondSlideMobile } from './landing_slider_second_slide_376x627.jpg';
export { default as alliedTransportImageCarouselThirdSlideMobile } from './landing_slider_third_slide_376x627.jpg';
export { default as alliedTransportImageCarouselFourthSlideMobile } from './landing_slider_fourth_slide_1067х800a.jpg';

export { default as alaskaBgFirst } from './bg_alaska_first.jpg';
export { default as alaskaBgFirstMobile } from './alaska_bg_first_374x260.jpg';
export { default as alaskaBgSecond } from './alaska_bg_second.jpg';
export { default as alaskaBgSecondMobile } from './alska_bg_second_374x260.jpg';
export { default as shipHoldingYacht } from './ship_holding_yacht.jpg';
export { default as shipHoldingYachtMobile } from './ship_holding_yacht_375x469.jpg';

export { default as divider } from './divider.svg';
export { default as alaskaRouteImage } from './alaska_route_map.jpg';
export { default as supportServices } from './support_services.jpg';

export { default as fish } from './fish.jpg';
export { default as bear } from './bear.jpg';
export { default as seal } from './seal.jpg';

export { default as exploration } from './exploration_section_image.jpg';
export { default as shipWithYachts } from './ship_with_yachts.jpg';

export { default as logoBlackSm } from './logo_black_sm.svg';
export { default as logoBlack } from './logo_black.svg';
export { default as clippedRectangle } from './clippedRectangle.svg';

export { default as daisy } from './daisy.jpg';
export { default as daisy1 } from './daisy1.jpg';
export { default as daisy_small } from './daisy_small.jpg';
