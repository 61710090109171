import React from 'react';
import '../../../stylesheets/Hero.css';
import { useMediaQuery, useTheme } from '@material-ui/core';
import HeroMobile from './HeroMobile';

const HeroDesktop = React.lazy(() => import('./HeroDesktop'));

function HeroSection() {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));

  return <div>{matchesXs ? <HeroMobile /> : <HeroDesktop />}</div>;
}

export default HeroSection;
