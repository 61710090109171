import auth0 from 'auth0-js';
import store from '../store/store';
import { resetUserCredentials, setUserCredentials } from '../actions/auth';
import { RouteComponentProps } from 'react-router-dom';

interface AuthConfig {
  domain: string;
  audience: string;
  clientID: string;
  redirectUri: string;
  logoutUri: string;
}

export default class Auth {
  private _auth0: auth0.WebAuth;
  private _authConfig: AuthConfig = {
    domain: '',
    audience: '',
    clientID: '',
    redirectUri: '',
    logoutUri: ''
  };

  constructor(public history: RouteComponentProps['history']) {
    const {
      auth: { authConfig }
    } = store.getState();
    this._authConfig = authConfig;
    if (Object.keys(authConfig).length > 0) {
      this._auth0 = new auth0.WebAuth({
        domain: this._authConfig.domain,
        clientID: this._authConfig.clientID,
        redirectUri: this._authConfig.redirectUri,
        audience: this._authConfig.audience,
        responseType: 'token id_token',
        scope: 'openid profile email update:schedule'
      });
    }
  }

  login = () => {
    this._auth0.authorize();
  };

  handleAuthentication = () => {
    this._auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.history.push('/');
      } else if (err) {
        this.history.push('/');
      }
    });
  };

  setSession = authResult => {
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    const accessToken = authResult.accessToken;
    const idToken = authResult.idToken;
    const userCredentials = {
      accessToken: accessToken,
      idToken: idToken,
      scope: authResult.scope,
      expiresAt: expiresAt,
      isAuthenticated: true,
      user: {
        name: authResult.idTokenPayload.name,
        email: authResult.idTokenPayload.email
      }
    };
    store.dispatch(setUserCredentials(userCredentials));
    this.scheduleTokenRenewal(expiresAt);
  };

  logout = () => {
    store.dispatch(resetUserCredentials());
    this._auth0.logout({
      clientID: this._authConfig.clientID,
      returnTo: this._authConfig.logoutUri
    });
  };

  renewToken(cb?: Function) {
    this._auth0.checkSession({}, (err, result) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.log(`Error anew Auth0 session: ${err.error} - ${err.error_description}`);
      } else {
        this.setSession(result);
      }

      if (cb) cb(err, result);
    });
  }

  scheduleTokenRenewal(expiresAt) {
    const delay = expiresAt - Date.now();
    if (delay > 0) setTimeout(() => this.renewToken(), delay);
  }

  checkIfUserHasScope(scopes: string[]) {
    const { auth } = store.getState();
    const availableScopes = auth.scope.split(' ');
    return scopes.every(scope => availableScopes.includes(scope));
  }
}
